import axios from "axios";
import jwt_decode from "jwt-decode";
import Config from '../Auth/Config';

export default class AuthService extends Config  {

    login(usuario, senha) {
        return axios({
            method: 'post',
            url: this._api_url + 'oauth/token',
            withCredentials: true,
            data: 'username=' + usuario + '&password=' + senha + '&grant_type=password',
            headers: {
                'Authorization': 'Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    logout() {
        let token = sessionStorage.getItem('token');
        token = JSON.parse(token);
        return axios({
            method: 'delete',
            url: this._api_url + 'tokens/revoke',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token.access_token
            },
        });
    }
    obterNovoAccessToken() {
        return axios({
            method: 'post',
            url: this._api_url + 'oauth/token',
            withCredentials: true,
            data: 'grant_type=refresh_token',
            headers: {
                'Authorization': 'Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    verificarTokenExistente() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        //console.log(token)
        if (token === null || token === "") {
            // console.log('não tem token');
            return false;
        } else {
            //  console.log('Tem token');
            return true;
        }
    }
    verificarTokenExpirado() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        let decodeToken = jwt_decode(token.access_token);
        // console.log(decodeToken);
        //console.log(token);

        let d1 = new Date(Date.now());
        let d2 = new Date(decodeToken.exp * 1000);

        if (d1 >= d2) {
            // console.log("Expirado");
            return true;
        } else {
            // console.log("Não Expirado");
            return false;
        }
    }
    limparAccessToken() {
        sessionStorage.removeItem('token');
    }
    armazenaToken(token) {
        sessionStorage.setItem('token', JSON.stringify(token));
    }
    token() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        return token.access_token;
    }
    jwtDecode() {
        let payLoad = jwt_decode(this.token());
        return payLoad;
    }
    checarToken(metodo) {
        if (this.verificarTokenExpirado() === true) {
           //console.log("Token Expirado")
            this.obterNovoAccessToken().then((response) => {
                //console.log("obtendo novo acess Token");
                if (response.status === 200) {
                    //console.log("Acess Token Obtido")
                    this.armazenaToken(response.data);
                    return metodo;
                }
            });
        //    console.log(metodo)
        } else {
        //    console.log(metodo);
            return metodo;
        }
    }

}