import firebase from "firebase/app";
import "firebase/storage";

let firebaseConfig = {
    apiKey: "AIzaSyAlqaI2iktVGb4lt6VvdEsbSqshck4R3Ys",
    authDomain: "portaria-sespa.firebaseapp.com",
    projectId: "portaria-sespa",
    storageBucket: "portaria-sespa.appspot.com",
    messagingSenderId: "947418013751",
    appId: "1:947418013751:web:e5291fe8410c01684d1fb2",
    measurementId: "G-B1W7822TWD"
};

firebase.initializeApp(firebaseConfig);

const firebaseApp = firebase;

export default firebaseApp;