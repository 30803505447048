import { createRouter, createWebHashHistory } from 'vue-router';
import AppLogin from './AppLogin.vue';
import Auth from './service/Auth/AuthService';

const auth = new Auth();

const routes = [
    {
        path: '/',
        name: 'login',
        component: AppLogin,
    },
    {
        path: '/controle-acesso',
        name: 'controle-acesso',
        component: () => import('./pages/RegistroBatida/RelatorioPontoDiarioPublic.vue'),
    },
    {
        path: '/appSystem',
        component: () => import('./AppSystem.vue'),
        beforeEnter: (to, from, next) => {
            if (auth.token()) {
                next()
            }
        },
        children: [
            {
                path: '',
                component: () => import('./pages/Dashboard.vue'),
            },
            {
                path: '/usuario',
                component: () => import('./pages/Usuario/Usuario.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/setor',
                component: () => import('./pages/Institucional/Setor.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/funcaoAndCargo',
                component: () => import('./pages/Institucional/FuncaoAndCargo.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/categoria',
                component: () => import('./pages/PessoasAndServidores/Categoria.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/pessoa',
                component: () => import('./pages/PessoasAndServidores/Pessoa.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/cadastrarVisitante',
                component: () => import('./pages/Visitante/CadastrarVisitante.vue'),
            },
            {
                path: '/entradaVisitante',
                component: () => import('./pages/Visitante/EntradaVisitante.vue'),
            },
            {
                path: '/saidaVisitante',
                component: () => import('./pages/Visitante/SaidaVisitante.vue'),
            },
            {
                path: '/cracha',
                component: () => import('./pages/GestaoDeAcesso/Cracha.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/equipamentos',
                component: () => import('./pages/GestaoDeAcesso/Equipamentos.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/pontoDiario',
                component: () => import('./pages/RegistroBatida/RelatorioBatidaDiario.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
            {
                path: '/pontoMensal',
                component: () => import('./pages/RegistroBatida/RelatorioBatidaMensal.vue'),
                beforeEnter: (to, from, next) => {
                    if (auth.jwtDecode().tipo === 'Administrador') {
                        next()
                    }
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
