<template>
  <Toast />
  <div class="p-grid full">
    <!-- INICIO FORM LOGIN-->
    <div class="p-col-12 p-lg-3 fullScreen">
      <div class="p-shadow-4 formlogin login">
        <!--header form 30%-->
        <div class="headerForm">
          <br /><br />
          <div class="p-d-flex p-ai-center p-jc-center ctisDiv">
            <img
              src="../public/assets/layout/images/sespa/logoCtis.svg"
              alt="Ctis"
              class="ctis"
            />
          </div>
        </div>
        <!-- FIM header form -->
        <!--INICIO BODY FORM LOGIN-->
        <div class="bodyForm">
          <div class="p-d-flex p-ai-center p-jc-center p-fluid p-grid">
            <div class="p-field p-col-8 p-md-7 p-shadow-4 formulario">
              <div class="p-d-flex p-ai-center p-jc-center p-mb-6">
                <img
                  src="../public/assets/layout/images/sespa/logoEstado.svg"
                  alt="SESPA"
                  width="250"
                />
              </div>
              <form @submit.prevent="logar()">
                <div class="p-d-flex p-ai-center p-jc-center">
                  <InputText
                    id="usuario"
                    class="p-col-8 p-text-center"
                    :class="{ 'p-invalid': submitted && !usuario }"
                    placeholder="Usuario"
                    required
                    v-model="usuario"
                    v-tooltip.right="'Informe o usuário!'"
                    autofocus
                  />
                </div>
                <br />
                <div class="p-d-flex p-ai-center p-jc-center">
                  <InputText
                    id="senha"
                    class="p-col-8 p-text-center"
                    :class="{ 'p-invalid': submitted && !senha }"
                    placeholder="Senha"
                    type="password"
                    required
                    v-model="senha"
                    v-tooltip.right="'Informe a Senha!'"
                  />
                </div>
                <br />
                <div class="p-d-flex p-ai-center p-jc-center">
                  <!--<a href="#" @click="esqueceuSenha()">Esqueceu sua senha?</a>-->
                </div>
                <div class="p-d-flex p-ai-center p-jc-center">
                  <Button
                    label="Acessar"
                    icon="pi pi-sign-in"
                    class="p-col-8 p-button-info p-mb-2"
                    type="submit"
                  ></Button>
                </div>

                <div class="p-d-flex p-ai-center p-jc-center">
                  <Button
                    label="Controle de Acesso"
                    icon="pi pi-clock"
                    class="p-col-8 p-button-success"
                    @click="controleAcesso()"
                  ></Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--fim body form -->
        <!--footer form 20%-->
        <div class="layout-footer">
          <div class="p-text-left">
            <div class="p-text-center footer">
              <span class="footer-text"
                >&copy; CTIS - Coordenação de Tecnologia em Saúde
              </span>
            </div>
          </div>
        </div>
        <!--footer form -->
      </div>
    </div>
    <!--FIM FORM LOGIN-->

    <!--INICIO PAINEL LOGIN-->
    <div class="p-col-12 p-lg-9 fullScree painelGeral">
      <div class="p-shadow-4 formPainel painel">
        <!--HEADER PAINEL -->
        <div class="headerPainel">
          <div class="p-d-flex p-ai-center p-jc-center"></div>
        </div>
        <!--FIM HEADER PAINEL-->
        <!--BODY PAINEL-->
        <div class="bodyPainel">
          <div class="p-d-flex p-ai-center p-jc-center">
            <div class="p-col-12 p-lg-7"></div>
          </div>
        </div>
        <!--FIM BODY PAINEL-->
        <!--FOOTER PAINEL-->
        <div class="footerPainel">
          <div class="layout-footer">
            <div class="p-text-right footer">
              <img
                src="../public/assets/layout/images/sespa/logoEstado.svg"
                alt="SESPA"
                width="70"
              />
              <span class="footer-text"> V 1.1 </span>
            </div>
          </div>
        </div>
        <!--FIM FOOTER PAINEL-->
      </div>
    </div>
    <!-- FIM INICIO PAINEL LOGIN-->

    <!--MODAL CADASTRAR-->
    <Dialog
      v-model:visible="usuarioDialog"
      :style="{ width: '500px' }"
      :modal="true"
    >
      <template #header>
        <h3>
          <i class="pi pi-user-plus" style="fontsize: 2rem"></i> - Cadastro de
          Usuario
        </h3>
      </template>
      <br />
      <div class="p-shadow-4" style="padding: 30px">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="nome">Nome</label>
            <span class="p-input-icon-left">
              <i class="pi pi-user" />
              <InputText id="nome" class="p-inputtext-lg" autofocus />
            </span>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="cpf">CPF</label>
            <span class="p-input-icon-left">
              <i class="pi pi-id-card" />
              <InputMask
                id="cpf"
                class="p-inputtext-lg"
                mask="999.999.999-99"
              />
            </span>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="contato">Contato</label>
            <span class="p-input-icon-left">
              <i class="pi pi-mobile" />
              <InputMask
                id="contato"
                class="p-inputtext-lg"
                mask="(99) 99999-9999"
              />
            </span>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="email">Email</label>
            <span class="p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText id="email" class="p-inputtext-lg" type="email" />
            </span>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="senha">Senha</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lock" />
              <InputText id="senha" class="p-inputtext-lg" type="password" />
            </span>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="confSenha">Confirmar Senha</label>
            <span class="p-input-icon-left">
              <i class="pi pi-lock" />
              <InputText
                id="confSenha"
                class="p-inputtext-lg"
                type="password"
              />
            </span>
          </div>
        </div>
      </div>
      <hr />
      <template #footer>
        <Button
          label="Salvar"
          icon="pi pi-check"
          class="p-button-raised p-button-info"
          @click="salvarUsuario()"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-raised p-button-danger"
          @click="usuarioDialog = false"
        />
      </template>
    </Dialog>
    <!--FIM MODAL CADASTRAR-->

    <!-- MODAL ESQUECEU SENHA-->
    <Dialog
      header="Recuperção de Senha"
      v-model:visible="esqueceuSenhaDialog"
      :style="{ width: '450px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="confSenha">Informe o Email</label>
            <span class="p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText id="confSenha" class="p-inputtext-lg" type="email" />
            </span>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Enviar"
          icon="pi pi-check"
          @click="enviarSenha()"
          class="p-button-raised p-button-info"
          autofocus
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="esqueceuSenhaDialog = false"
          class="p-button-raised p-button-danger"
        />
      </template>
    </Dialog>
    <!--FIM MODAL ESQUECEU SENHA-->
  </div>
</template>


<script>
import AuthService from "./service/Auth/AuthService";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      usuario: null,
      senha: null,
      submitted: false,
      usuarioDialog: false,
      esqueceuSenhaDialog: false,
    };
  },
  authService: null,
  produtoService: null,
  created() {
    this.authService = new AuthService();
    if (sessionStorage.getItem("token")) {
      this.$router.push("/appSystem");
    } else {
      this.$router.push("/");
    }
  },
  mounted() {},
  methods: {
    async logar() {
      this.authService
        .login(this.usuario, this.senha)
        .then((response) => {
          this.authService.armazenaToken(response.data);
          this.$router.push("/appSystem");
        })
        .catch((error) => {
          if (
            error.response.status === 400 &&
            error.response.data.error_description ===
              "Usu&aacute;rio desabilitado"
          ) {
            this.$toast.add({
              severity: "warn",
              summary: "Atenção!",
              detail:
                "Usuário que esta tentando logar esta inativo. Entre em contato com ADMINISTRADOR para ativar!",
            });
          } else if (
            error.response.status === 400 &&
            error.response.data.error_description ===
              "Usu&aacute;rio inexistente ou senha inv&aacute;lida"
          ) {
            this.$toast.add({
              severity: "error",
              summary: "Atenção!",
              detail: "Usuario e senha incorretos, tente novamente!",
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Atenção!",
              detail: "Error entre com contato com o desenvolvedor",
            });
          }
        });
    },
    controleAcesso() {
      this.$router.push("controle-acesso")
    },
    esqueceuSenha() {
      this.esqueceuSenhaDialog = true;
    },
  },
};
</script>


<style lang="scss" scoped>
.content {
  color: #ffffff;
  display: table-cell;
  vertical-align: middle;
  padding: 7%;
}

.fullScreen {
  height: 99vh;
  overflow: hidden;
  margin-left: -5px;
}
.formlogin {
  height: 99%;
  margin-top: 3px;
  margin-left: 10px;
}
.formPainel {
  margin-top: 3px;
  height: 99%;
  margin-left: 10px;
}
.login {
  background-image: url("../public/assets/layout/images/sespa/fundo.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover*/
}
.painel {
  background-image: radial-gradient(#4169e1, #0288d1);
}
.headerForm {
  height: 40%;
}
.bodyForm {
  height: 53%;
}
.headerPainel {
  height: 20%;
}
.bodyPainel {
  height: 72.3%;
}
.layout-footer {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.cadastro {
  background-color: #0288d1;
  background-image: radial-gradient(#4169e1, #0288d1);
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.formulario {
  width: 80%;
  padding: 10%;
}
.footer {
  font-size: 12px;
}

.ctisDiv {
  width: 100%;
}
.ctis {
  width: 70%;
}
.full {
  margin: 0px auto;
}
@media screen and (max-width: 40em) {
  .painelGeral {
    display: none !important;
  }
}
</style>